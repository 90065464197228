<template>
  <div class="last-content">
    <div class="box">
      <div class="last-text">现在，非常期待与您的又一次邂逅</div>
      <div class="last-btn">
        <div class="contant">
          <span>立即联系</span>
        </div>
        <div
          class="play"
          @click="(dialogVisible = true), clickMenu('免费试用')"
        >
          <span>预约演示</span>
        </div>
      </div>
    </div>
    <demonstrate
      :dialogVisible="dialogVisible"
      @closeDialog="closeDialog"
    ></demonstrate>
  </div>
</template>
<script>
import { staClickMenu } from "../utils/index";
import demonstrate from "../components/demonstrate.vue";
export default {
  components: {
    demonstrate,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    // 统计菜单点击
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != "免费试用") {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName);
    },
    closeDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.last-content {
  width: 100%;
  height: 240px;
  background: url(~@/assets/sdImages/home/Background.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;

  .box {
    width: 1200px;
    position: relative;
    overflow: hidden;
    height: 100%;
    margin: auto;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: 100% auto;

    .last-text {
      margin-top: 70px;
      margin-bottom: 20px;
      // font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #262626;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .last-btn {
      display: flex;
      overflow: hidden;
      align-items: center;

      .contant {
        display: flex;
        height: 48px;
        width: 134px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        transition: 0.2s;
        border-radius: 8px;
        background: linear-gradient(
            225deg,
            #3be7fe -11.69%,
            rgba(59, 184, 254, 0) 22.91%
          ),
          linear-gradient(42deg, #3be7fe -19.17%, rgba(59, 184, 254, 0) 27.1%),
          linear-gradient(312deg, #217aff 35%, #4434ff 104.66%);
        box-shadow: 0 12px 15px 0 rgba(28, 65, 149, 0.13);
        min-width: 80px;
        color: #fff;
        position: relative;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
      }

      .contant:hover {
        background: linear-gradient(
            0deg,
            hsla(0, 0%, 100%, 0.08),
            hsla(0, 0%, 100%, 0.08)
          ),
          linear-gradient(225deg, #3be7fe -11.69%, rgba(59, 184, 254, 0) 22.91%),
          linear-gradient(42deg, #3be7fe -19.17%, rgba(59, 184, 254, 0) 27.1%),
          linear-gradient(312deg, #217aff 35%, #4434ff 104.66%), #3a89fe;
      }

      .play {
        margin-left: 24px;
        float: left;
        width: 118px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        border: 1px solid #3a89fe;
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        background: transparent;
        color: #3a89fe;
        cursor: pointer;
      }

      .play:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
